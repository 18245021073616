<template>
  <transition name="fade">
    <div class="statusbox">
      <div v-if="loaded">
        <div class="detailbox profiles">
          <span v-if="flow == 'confirm'" class="backlink"
            ><router-link
              @click.native="scrollToTop"
              :to="{
                path: '/item/' + label + '/sender',
                query: { rd: 'back' },
              }"
              >編集</router-link
            ></span
          >

          <h3>ご注文者様情報</h3>

          <dl>
            <dt>ご注文者様名</dt>
            <dd>
              {{ fields.sei.value }}
              {{ fields.mei.value }}
              様
            </dd>
          </dl>
          <dl v-if="fields.sei_kana.value || fields.mei_kana.value">
            <dt>ご注文者様名ふりがな</dt>
            <dd>
              {{ fields.sei_kana.value }}
              {{ fields.mei_kana.value }}
              さま
            </dd>
          </dl>

          <dl>
            <dt>ご注文者様住所</dt>
            <dd>
              〒{{ fields.zip.value }}<br />
              {{ fields.state.value }} {{ fields.city.value }}
              {{ fields.street.value }}
              <br />
              {{ fields.building.values }} {{ fields.other.value }}
            </dd>
          </dl>

          <dl>
            <dt>ご注文者様電話番号</dt>
            <dd>
              {{ fields.tel.value }}
            </dd>
          </dl>

          <dl v-if="fields.email.value">
            <dt>メールアドレス</dt>
            <dd>
              {{ fields.email.value }}
            </dd>
          </dl>
        </div>

        <div
          class="deliverone"
          v-for="(mydeliver, i) in fields.mydeliver.subfields"
          :key="i"
        >
          <h1 v-if="fields.mydeliver.subfields.length > 1">
            {{ i + 1 }}枚目の内容
          </h1>
          <h1 v-else>ご注文内容</h1>
          <h3>{{ orderData.fields.items.subfields[i].item.textvalue }}</h3>
          <h2>
            【{{ tagged_values[i].letter_callname }}】 【{{
              tagged_values[i].letter_target
            }}】 【{{ tagged_values[i].letter_lang }}】
          </h2>

          <div class="confirmbox">
            <div class="previewbox">
              <div v-if="!previewImages.length">
                しばらくお待ちください。プレビューを読み込み中です..
              </div>
              <div v-else>
                <vue-easy-lightbox
                  :escDisabled="false"
                  :moveDisabled="false"
                  closeText="閉じる"
                  :visible="imgvisible(i).visible"
                  :imgs="previewImages[i]"
                  :index="imgvisible(i).index"
                  @hide="imghide(i)"
                >
                  <template v-slot:toolbar="{ toolbarMethods }">
                    <div class="vel-toolbar" prefixcls="vel">
                      <div
                        class="toolbar-btn toolbar-btn__zoomin"
                        @click="toolbarMethods.zoomIn"
                      >
                        <svg aria-hidden="true" class="vel-icon icon">
                          <use xlink:href="#icon-zoomin"></use></svg
                        >拡大
                      </div>
                      <div
                        class="toolbar-btn toolbar-btn__zoomout"
                        @click="toolbarMethods.zoomOut"
                      >
                        <svg aria-hidden="true" class="vel-icon icon">
                          <use xlink:href="#icon-zoomout"></use></svg
                        >縮小
                      </div>
                    </div>
                  </template>
                </vue-easy-lightbox>

                <ul>
                  <li v-for="(img, i2) in previewImages[i]" :key="i2">
                    <img
                      class="letter_previewimg"
                      :src="img"
                      alt=""
                      @click="imgview(i, i2)"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div class="detailbox">
              <h3>
                お申込内容

                <span class="backlink" v-if="flow == 'confirm'"
                  ><router-link
                    @click.native="scrollToTop"
                    :to="{
                      path: '/item/' + label + '/#item' + (i + 1),
                      query: { rd: 'back' },
                    }"
                    >編集</router-link
                  ></span
                >
              </h3>

              <plugin_itemoptions
                :orderData="orderData"
                :options_filtered="options_filter('view,option1')"
                :cartIndex="i"
                :posterrors="errors_global"
              />

              <h3>
                メッセージ

                <span v-if="flow == 'confirm'" class="backlink"
                  ><router-link
                    @click.native="scrollToTop"
                    :to="{
                      path: '/item/' + label + '/option1#item' + (i + 1),
                      query: { rd: 'back' },
                    }"
                    >編集</router-link
                  ></span
                >
              </h3>

              <dl>
                <dd>
                  <plugin_itemoptions
                    :orderData="orderData"
                    :options_filtered="options_filter('option2')"
                    :cartIndex="i"
                    :posterrors="errors_global"
                  />
                </dd>
              </dl>

              <p
                class="discount"
                v-if="firstOfObject(computed.delivers[i].items).discount"
              >
                割引: -{{
                  firstOfObject(computed.delivers[i].items).discount
                }}円
              </p>

              <p class="price">
                単価:
                {{ firstOfObject(computed.delivers[i].items).price }}円(税込)
              </p>

              <h3>
                オプション商品

                <span class="backlink" v-if="flow == 'confirm'"
                  ><router-link
                    @click.native="scrollToTop"
                    :to="{
                      path:
                        '/item/' +
                        label +
                        '/option1#item' +
                        (i + 1) +
                        '-options',
                      query: { rd: 'back' },
                    }"
                    >編集</router-link
                  ></span
                >
              </h3>

              <dl>
                <dd>
                  <plugin_itemoptions
                    :orderData="orderData"
                    :options_filtered="options_filter('deliver1,deliver2')"
                    :cartIndex="i"
                    :posterrors="errors_global"
                  />
                </dd>
              </dl>

              <h3>配送方法</h3>

              <div
                v-for="(deliver, d) in computed.delivers[i].delivermethods"
                :key="d"
              >
                <div v-if="mydeliver.type.value == deliver.ID">
                  <dl>
                    <dt>配送方法</dt>
                    <dd>
                      <p>
                        <label>
                          {{ deliver.label }}
                          ({{ deliver.area }} / {{ deliver.size }})</label
                        >
                      </p>
                    </dd>
                  </dl>

                  <dl v-if="mydeliver.date.value">
                    <dt>お届け希望日</dt>
                    <dd>
                      {{
                        mydeliver.date.textvalue
                          ? mydeliver.date.textvalue
                          : mydeliver.date.value
                      }}
                    </dd>
                  </dl>

                  <dl v-if="mydeliver.time.value">
                    <dt>お届け希望時間帯</dt>
                    <dd>
                      {{
                        mydeliver.time.textvalue
                          ? mydeliver.time.textvalue
                          : mydeliver.time.value
                      }}
                    </dd>
                  </dl>
                  <dl v-if="mydeliver.code.value">
                    <dt>荷物追跡番号</dt>
                    <dd>
                      {{ mydeliver.code.textvalue }}
                    </dd>
                  </dl>

                  <dl v-if="mydeliver.status.value">
                    <dt>配送状況</dt>
                    <dd>
                      {{ mydeliver.status.textvalue }}
                    </dd>
                  </dl>
                  <div class="price">配送料:{{ deliver.cost }}円</div>
                </div>
              </div>

              <h3>
                お届け先の情報

                <span class="backlink" v-if="flow == 'confirm'"
                  ><router-link
                    @click.native="scrollToTop"
                    :to="{
                      path: '/item/' + label + '/deliver#item' + (i + 1),
                      query: { rd: 'back' },
                    }"
                    >編集</router-link
                  ></span
                >
              </h3>

              <dl>
                <dt>お届け先名</dt>
                <dd>
                  {{ mydeliver.sei.value }}
                  {{ mydeliver.mei.value }}
                  様
                </dd>
              </dl>

              <dl v-if="mydeliver.sei_kana.value || mydeliver.mei_kana.value">
                <dt>お届け先名ふりがな</dt>
                <dd>
                  {{ mydeliver.sei_kana.value }}
                  {{ mydeliver.mei_kana.value }}
                  さま
                </dd>
              </dl>

              <dl>
                <dt>お届け先住所</dt>
                <dd>
                  〒{{ mydeliver.zip.value }}<br />
                  {{ mydeliver.state.value }}
                  {{ mydeliver.city.value }}
                  {{ mydeliver.street.value }}<br />
                  {{ mydeliver.building.value }}
                  {{ mydeliver.other.value }}
                </dd>
              </dl>

              <dl>
                <dt>お届け先電話番号</dt>
                <dd>{{ mydeliver.tel.value }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="loadercontent">読み込み中です..</div>
      </div>
    </div>
  </transition>
</template>


<style scoped lang="scss">
h1 {
  background: #d72e23;
  color: #fff;
}
.detailbox {
  line-height: 1.7;
  text-align: left;
  padding: 1em;
  flex-grow: 100;
  &.profiles {
    border: solid 1px #ccc;
    padding: 2em;
    margin-top: 2em;
  }
}
.detailbox dt {
  border-bottom: solid 1px #ccc;
  font-size: 0.8em;
  font-weight: bold;
  color: rgb(84, 83, 92);
}

.detailbox dl {
  margin-bottom: 0.5em;
}
.previewbox {
  background: rgb(245, 245, 245);
  text-align: left;
  padding: 0.5em;
  ul {
    list-style: none;
  }
}
.letter_previewimg {
  border: solid 1px #b0b0b0;
  width: 200px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.confirmbox {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}
.deliverone {
  margin-top: 2em;
}

@mixin sp {
  @media (max-width: (480px)) {
    @content;
  }
}
.vel-toolbar {
  display: flex;
  position: fixed;
  bottom: 5vh;
  width: 100%;
  justify-content: center;
  @include sp {
    font-size: 7vw;
  }
  svg {
    width: 1em;
    height: 1em;
  }
  .toolbar-btn {
    cursor: pointer;
    background: rgb(179, 179, 179);
    padding: 0.3em 1em;
    border-radius: 0.5em;
    margin-right: 0.6em;
    &:hover {
      opacity: 0.8;
    }
  }
}
.backlink {
  float: right;
  a {
    color: #fff;
    background: rgb(160, 160, 160);
    padding: 0.3em 1em;
    display: inline-block;
    text-decoration: none;
    font-size: 0.75em;
    border-radius: 0.7em;
    font-weight: normal;
    &:hover {
      background: red;
    }
  }
}
.price {
  background: rgb(245, 245, 245);
  text-align: right;
  font-size: 0.9em;
  font-weight: bold;
  color: rgb(71, 20, 20);
  margin-bottom: 0.5em;
}
.discount {
  background: rgb(227, 240, 255);
  text-align: right;
  font-size: 0.9em;
  font-weight: bold;
  color: rgb(71, 20, 20);
  margin-bottom: 0.5em;
}
</style>

<script>
import plugin_itemoptions from "@/components/itemoptionview";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  components: { plugin_itemoptions, VueEasyLightbox },
  props: ["orderData", "flow", "options_prop"],
  data: function () {
    return {
      values: {},
      previewHTML: "",
      previewImages: [],
      tagged_values: [],
      computed: {},
      imgviewflg: [],
      show_price: true,
    };
  },
  created: function () {
    this.loaded_countmax = 2;

    if (this.orderData.fields.prices.value) {
      if (typeof this.orderData.fields.prices.value == "string") {
        this.computed = JSON.parse(this.orderData.fields.prices.value);
      } else {
        this.computed = this.orderData.fields.prices.value;
      }
    }

    this.get_itemoption(this.label).then(() => {
      this.values = this.orderData.fields.items.subfields;
      var tagged_values = [];
      var unit = this.orderData.fields.items.subfields.length;
      for (let i = 0; i < unit; i++) {
        let options = this.orderData.fields.items.subfields[i].item.options;
        //console.log("this.get_tagged_values(this.get_option_value(i))",this.get_tagged_values(this.get_option_value(i),options))
        tagged_values.push(
          this.get_tagged_values(this.get_option_value(i), options)
        );
      }
      if (!this.options.length) this.options = this.options_prop;

      for (var t = 0; t < tagged_values.length; t++) {
        console.log("tagged_values", t, tagged_values[t]);
        if (
          typeof tagged_values[t].letternumber != "undefined" &&
          /^SP[0-9]+/.test(tagged_values[t].letternumber)
        ) {
          this.$parent.show_price = false;
          console.log("申し込みセット", this.$parent);
        }
      }

      this.tagged_values = tagged_values;
      this.loaded_countup();
    });

    //var record=this.getSession("record");
    //まず商品定義を読み込む
    this.fetch("previewcreate", {
      label: this.label,
      record: this.orderData,
    }).then((data) => {
      console.log(data);
      //this.previewHTML=data;
      this.previewImages = data.preview;
      this.loaded_countup();
    });
  },
  computed: {
    fields: function () {
      return this.orderData.fields;
    },
    label: function () {
      return this.$route.params.label;
    },
  },
  methods: {
    firstOfObject: function (val) {
      if (Array.isArray(val)) {
        return val[0];
      } else {
        return val[Object.keys(val)[0]];
      }
    },
    imgvisible: function (index) {
      if (
        typeof this.imgviewflg[index] == "undefined" ||
        !this.imgviewflg[index]
      ) {
        return { index: 0, visible: false };
      }
      return this.imgviewflg[index];
    },
    imghide: function (index) {
      this.$set(this.imgviewflg, index, { index: 0, visible: false });
    },
    imgview: function (index, imgindex) {
      this.$set(this.imgviewflg, index, { index: imgindex, visible: true });
    },
    get_option_value(index) {
      if (this.values[index].options.value) {
        return JSON.parse(this.values[index].options.value);
      } else {
        return [];
      }
    },
  },
};
</script>