<template>
  <transition name="fade">
    <div class="itemoptions">
      <div v-if="!options_filtered.length">
        (この商品にオプションはありません)
      </div>

      <div v-else-if="!option_selected_all()">(選択されていません)</div>
      <!--
<br>
	現在の選択値:{{values}}<br>-->

      <ul v-else>
        <li v-for="(option, i) in options_filtered" :key="i" :data-index="i">
          <dl v-if="option_selected(i)">
            <dt>{{ option.fields.title.value }}</dt>

            <dd v-if="option.fields.type.value == 'textarea'">
              {{ getVal(i, 0) }}
            </dd>

            <dd v-if="option.fields.type.value == 'text'">
              {{ getVal(i, 0) }}
            </dd>

            <dd v-if="option.fields.type.value == 'select'">
              <div
                v-for="(selector, s) in option.fields.selector.subfields"
                :key="s"
              >
                <div v-if="option_selected_child(i, s)">
                  {{ selector.name.value }}
                </div>
              </div>
            </dd>

            <dd v-if="option.fields.type.value == 'check'">
              <div
                class="option_child"
                v-for="(selector, s) in option.fields.selector.subfields"
                :key="s"
              >
                <div v-if="option_selected_child(i, s)">
                  {{ selector.name.value }}
                </div>
              </div>
            </dd>

            <dd v-if="option.fields.type.value == 'radio'">
              <div
                v-for="(selector, s) in option.fields.selector.subfields"
                :key="s"
              >
                <div v-if="option_selected_child(i, s)">
                  {{ selector.name.value }}
                </div>
              </div>
            </dd>

            <dd
              v-if="
                option.fields.type.value == 'item' &&
                option.fields.multiple.value == 'select'
              "
            >
              <div>
                <div
                  class="inline"
                  v-for="(selector, s) in option.fields.items.subfields"
                  :key="s"
                >
                  <div v-if="option_selected_child(i, s)">
                    <label>
                      {{
                        selector.name.value
                          ? selector.name.value
                          : selector.item.textvalue
                      }}

                      (個数：{{ getunit(i, s) }})
                      <!--	<span v-if="selector.max.value!=='' && selector.max.value==0">在庫切れ</span>-->
                    </label>
                    <p class="price">
                      オプション:+{{ selector.record.price.value }}円(税込)
                    </p>
                  </div>
                </div>
              </div>
            </dd>
            <dd
              v-if="
                option.fields.type.value == 'item' &&
                option.fields.multiple.value == 'add'
              "
            >
              <div
                class="option_child"
                v-for="(selector, s) in option.fields.items.subfields"
                :key="s"
              >
                <div v-if="option_selected_child(i, s)">
                  <label>
                    {{
                      selector.name.value
                        ? selector.name.value
                        : selector.item.textvalue
                    }}

                    <!--<span v-if="selector.max.value!=='' && selector.max.value==0">在庫切れ</span>-->
                  </label>
                  <p class="price">
                    オプション:{{ selector.record.price.value }}円<span
                      v-if="getunit(i, s) != 1"
                      >×{{ getunit(i, s) }}</span
                    >(税込)
                  </p>
                </div>
              </div>
            </dd>
          </dl>
          <inlineError
            :name="'items-' + cartIndex + '-option' + option.ID"
            :errors="posterrors"
          />
        </li>
      </ul>
    </div>
  </transition>
</template>


<style scoped>
.itemoptions {
}
.itemoptions ul {
  padding: 0;
  list-style: none;
}
.itemoptions ul li {
}
.itemoptions ul li dl {
}
.itemoptions ul li.selected {
  background: #e1e6ff;
}
.itemoptions {
}
.itemoptions dl {
  margin-bottom: 0.5em;
}
.itemoptions dl dd {
}

.itemoptions dl dt {
  border-bottom: solid 1px #ccc;
  font-size: 0.8em;
  font-weight: bold;
  color: rgb(84, 83, 92);
  background: none !important;
}

.itemoptions dl dd .option_child.selected {
  font-weight: bold;
}
.itemoption_error {
  margin: 0 0 0.5em 0;
  background: yellow;
  padding: 0.3em 0.7em;
}
.price {
  background: rgb(245, 245, 245);
  text-align: right;
  font-size: 0.9em;
  font-weight: bold;
  color: rgb(71, 20, 20);
  margin-bottom: 0.5em;
}
</style>

<script>
import inlineError from "./inlinError.vue";
export default {
  components: { inlineError },
  data: function () {
    return {
      //options:"",
      values: [],
      errors: [],
    };
  },
  computed: {},
  watch: {},
  props: ["orderData", "options_filtered", "cartIndex", "posterrors"],
  created: function () {
    console.log(
      "テストデバッグ",
      this.cartIndex,
      this.orderData,
      this.options_filtered
    );
    //this.values=this.setSession("itemoption_values",[],true);
    var optionValue =
      this.orderData.fields.items.subfields[this.cartIndex].options.value;
    if (optionValue) this.values = JSON.parse(optionValue);

    var prices = this.orderData.fields.prices.value;
    if (typeof prices == "string") prices = JSON.parse(prices);

    //console.log("初期値をセット開始,保存済み値は：",this.data);

    //var itemID=this.orderData._parent.subfields[this.orderData._index].item.value;
    //var arg={ID:itemID}
    //fetch("/admin/api/load_options",{method:'POST',headers: {'Content-Type': 'application/json'},body:JSON.stringify(arg)}).then(function(response){return response.json();}).then(function(data){
    //this.options_filtered=data;

    //未セットのデータは初期値をセット(選択必須の場合のみ、先頭のオプションを選択しておく)
    for (var i = 0; i < this.options_filtered.length; i++) {
      //テキスト入力型以外はすべて該当
      let matched = false;
      if (
        ["text", "textarea"].indexOf(
          this.options_filtered[i].fields.type.value
        ) == -1 &&
        this.options_filtered[i].fields.require.value == "on"
      ) {
        //console.log("getVal",this.getVal(i))
        if (this.getVal(i) === "" || this.getVal(i).length === 0) {
          matched = true;
          let tmp = this.getdefVal(i, 0);
          this.values.push({
            ID: this.options_filtered[i].ID,
            values: [{ value: tmp.value, label: tmp.label, unit: 1 }],
          });
        }
      }
      if (!matched) {
        /*
				let tmp=this.getdefVal(i,0)
                console.log("ついかするの2",tmp,this.getVal(i))
				this.values.push({
					ID:this.options_filtered[i].ID,
					values:[{value:tmp.value,label:tmp.label,unit:1}]
                });*/
      }
    }

    //}.bind(this))

    //alert(1)
  },
  methods: {
    error_merged: function () {
      let errors = this.errors;
      for (let i = 0; i < this.options_filtered.length; i++) {
        if (
          typeof this.posterrors[
            "field_items-" +
              this.cartIndex +
              "-option" +
              this.options_filtered[i].ID
          ] != "undefined"
        ) {
          errors[i] =
            this.posterrors[
              "field_items-" +
                this.cartIndex +
                "-option" +
                this.options_filtered[i].ID
            ];
        }
      }
      return errors;
    },
    //全てのオプションの内に選ばれているものがあればtrue、選ばれていなければfalse
    option_selected_all: function () {
      var options = this.options_filtered;
      for (var i = 0; i < options.length; i++) {
        if (this.option_selected(i)) {
          return true;
        }
      }
      return false;
    },
    //該当のオプションの内に選ばれているものがあればtrue、選ばれていなければfalse
    option_selected: function (i) {
      var option = this.options_filtered[i];
      console.log(
        i + "番目のoptions",
        option.page_title,
        "(" + option.ID + ")を調査、持っている値は",
        this.values
      );
      for (var i2 = 0; i2 < this.values.length; i2++) {
        if (this.values[i2].ID == option.ID) {
          //console.log("sqsss",this.values[i2],option)
          if (option.fields.type.value == "item") {
            if (this.values[i2].values.length) {
              for (let n = 0; n < this.values[i2].values.length; n++) {
                for (
                  let n2 = 0;
                  n2 < option.fields.items.subfields.length;
                  n2++
                ) {
                  if (
                    this.values[i2].values[n].value ==
                    option.fields.items.subfields[n2].item.value
                  )
                    return true;
                }
              }
            }
          }
          //オプションが商品選択タイプの場合は、実際にいずれかの商品が選ばれている場合のみ有効に
          else if (this.values[i2].values.length) return true;
          break;
        }
      }
      return false;
    },
    //該当のオプションの内の選択肢が現在選ばれていればtrue、選ばれていなければfalse
    option_selected_child: function (i, s) {
      var option = this.options_filtered[i];
      let val = this.getdefVal(i, s);
      for (var i2 = 0; i2 < this.values.length; i2++) {
        if (this.values[i2].ID == option.ID) {
          for (var i3 = 0; i3 < this.values[i2].values.length; i3++) {
            if (this.values[i2].values[i3].value == val.value) {
              return true;
            }
          }
          break;
        }
      }
      return false;
    },
    setunit: function (i, s, v) {
      var option = this.options_filtered[i];
      let val = this.getdefVal(i, s);
      for (var i2 = 0; i2 < this.values.length; i2++) {
        if (this.values[i2].ID == option.ID) {
          for (var i3 = 0; i3 < this.values[i2].values.length; i3++) {
            if (this.values[i2].values[i3].value == val.value) {
              this.$set(this.values[i2].values[i3], "unit", v);
              break;
            }
          }
          break;
        }
      }
    },
    getunit: function (i, s) {
      let data = this.getVal(i, s, true);
      if (typeof data.unit == "undefined") {
        return 1;
      }
      return data.unit;
    },
    getVal: function (index, valueIndex, returnData) {
      if (typeof returnData == "undefined") returnData = false;
      var option = this.options_filtered[index];
      var optionVals = [];
      if (option.fields.type.value == "item") {
        for (let i = 0; i < option.fields.items.subfields.length; i++) {
          optionVals.push(option.fields.items.subfields[i].item.value);
        }
      } else {
        for (let i = 0; i < option.fields.selector.subfields.length; i++) {
          optionVals.push(option.fields.selector.subfields[i].name.value);
        }
      }
      for (var i = 0; i < this.values.length; i++) {
        if (this.values[i].ID == option.ID) {
          if (!this.values[i].values.length) {
            return "";
          }
          //入力型の場合はそのまま返す
          else if (
            ["text", "textarea"].indexOf(option.fields.type.value) != -1
          ) {
            if (returnData) return this.values[i].values[0];
            return this.values[i].values[0].value;
          }
          //一意型の選択値の場合は選択値が含まれている場合のみ選択値を返す
          else if (["select"].indexOf(option.fields.type.value) != -1) {
            let foundIndex = optionVals.indexOf(this.values[i].values[0].value);
            if (foundIndex != -1) {
              if (returnData) return this.values[i].values[0];
              return foundIndex;
            }
            return "";
          }

          let rtn = [];
          for (var s = 0; s < this.values[i].values.length; s++) {
            let foundIndex = optionVals.indexOf(this.values[i].values[s].value);
            if (foundIndex != -1) {
              rtn.push(foundIndex);
              if (foundIndex == valueIndex && returnData)
                return this.values[i].values[s];
            }
          } /**/

          if (returnData) return {};
          return rtn;
          //return this.values[i].values[valueIndex].value;
        }
      }
      if (
        ["text", "textarea", "select"].indexOf(option.fields.type.value) == -1
      ) {
        return [];
      }
      return "";
    },
    getdefVal: function (index, valueIndex) {
      var option = this.options_filtered[index];
      var rtn = {};
      if (
        ["check", "radio", "select"].indexOf(option.fields.type.value) !== -1
      ) {
        rtn.label = rtn.value =
          option.fields.selector.subfields[valueIndex].name.value;
        rtn.unit = this.getunit(index, valueIndex);
      } else if (["item"].indexOf(option.fields.type.value) !== -1) {
        rtn.value = option.fields.items.subfields[valueIndex].item.value;
        rtn.label = option.fields.items.subfields[valueIndex].item.textvalue;
        rtn.unit = this.getunit(index, valueIndex);
      }
      return rtn;
    },
    changeVal: function (e, index) {
      var multiple = false;
      var option = this.options_filtered[index];
      var unit = 1;
      var valueIndex = e.target.value;
      var checked = !e.target.value ? false : e.target.checked;
      var value = e.target.value;
      var label = e.target.value;
      var tmp = {};

      if (checked || value) {
        if (
          ["check", "radio", "select", "item"].indexOf(
            option.fields.type.value
          ) !== -1
        ) {
          tmp = this.getdefVal(index, valueIndex);
          label = tmp.label;
          value = tmp.value;
          unit = tmp.unit;
        }
      }

      if (
        "check" == option.fields.type.value ||
        ("item" == option.fields.type.value &&
          "add" == option.fields.multiple.value)
      ) {
        multiple = true;
      }

      //一意の値のフィールドの場合は、削除して追加
      if (!multiple) {
        let overwritten = false;
        for (var i = 0; i < this.values.length; i++) {
          if (this.values[i].ID == option.ID) {
            if (checked || value) {
              overwritten = true;
              this.values[i].values.splice(0, 1, {
                value: value,
                label: label,
                unit: unit,
              });
            } else {
              this.values[i].values.splice(0, 1);
              //this.values.splice(i, 1);
            }

            //複数値のフィールドの場合、選択解除の場合、その値を含む場合はその値を削除
            //複数値のフィールドの場合、選択追加の場合、その値を含まない場合はその値を追加
          }
        }
        //上書きする値が無かった場合は追加
        if ((checked || value) && !overwritten) {
          this.values.push({
            ID: option.ID,
            values: [{ value: value, label: label, unit: unit }],
          });
        }

        //複数値のフィールドの場合、選択解除の場合、その値を含む場合はその値を削除
        //複数値のフィールドの場合、選択追加の場合、その値を含まない場合はその値を追加
      } else {
        let matched = false;
        for (let i = 0; i < this.values.length; i++) {
          if (this.values[i].ID == option.ID) {
            matched = true;
            let overwritten = false;
            for (var i2 = 0; i2 < this.values[i].values.length; i2++) {
              //値が見つかった場合
              if (this.values[i].values[i2].value == value) {
                if (checked) {
                  overwritten = true;
                  this.values[i].values.splice(i2, 1, {
                    value: value,
                    label: label,
                    unit: unit,
                  });
                } else {
                  this.values[i].values.splice(i2, 1);
                }
              }
            }
            //上書きする値が無かった場合は追加
            if (checked && !overwritten)
              this.values[i].values.push({
                value: value,
                label: label,
                unit: unit,
              });
          }
        }
        if (!matched && checked) {
          if (checked) {
            this.values.push({
              ID: option.ID,
              values: [{ value: value, label: label, unit: unit }],
            });
          }
        }
      }
      if (value == "" && option.fields.require.value) {
        this.$set(this.errors, index, "入力は必須です");
        return;
      }

      this.$set(this.errors, index, "");
    },
  },
};
</script>